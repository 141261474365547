import React from "react"
import { jsx, css } from "@emotion/core"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import SEO from "../components/seo"
import Header from "../components/header"

const MEDIAQUERY = 1000

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <div>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <Header />
        <div
          css={css`
            float: left;
            width: 100%;
            background: white;
            padding-top: 175px;
            padding-bottom: 25px;
            @media (max-width: ${MEDIAQUERY}px) {
              width: 100%;
              padding-top: 125px;
              padding-bottom: 25px;
              margin-bottom: 0;
            }
          `}
        >
          <div
            css={css`
              width: 800px;
              margin: 0 auto;
              text-align: center;
              margin-bottom: 25px;
              @media (max-width: ${MEDIAQUERY}px) {
                width: 100%;
                padding-left: 25px;
                padding-right: 25px;
                box-sizing: border-box;
              }
            `}
          >
            <h1
              css={css`
                font-family: "Lora";
                font-size: 56px;
                font-weight: normal;
                line-height: 56px;
                padding: 0;
                margin: 0;
                text-decoration: none;
                @media (max-width: ${MEDIAQUERY}px) {
                  font-size: 30px;
                  font-weight: normal;
                  line-height: 30px;
                }
              `}
            >
              {post.frontmatter.title}
            </h1>
            <p>{post.frontmatter.date}</p>
          </div>
          <div
            css={css`
              float: left;
              width: ${post.frontmatter.type === "music" ? "50%" : "100%"};
              margin-left: 50%;
              transform: translateX(-50%);
              position: relative;
              @media (max-width: ${MEDIAQUERY}px) {
                width: ${post.frontmatter.type === "music" ? "80%" : "100%"};
                padding-left: 25px;
                padding-right: 25px;
                box-sizing: border-box;
              }
              p {
                font-family: "Lora";
                font-size: 16px;
                width: 725px;
                margin: 0 auto;
                margin-bottom: 25px;
                @media (max-width: ${MEDIAQUERY}px) {
                  width: 100%;
                }
              }
              p:first-of-type {
                font-family: monospace;
                font-weight: bold;
                margin-bottom: 50px;
                margin-top: 15px;
                @media (max-width: ${MEDIAQUERY}px) {
                  margin-bottom: 30px;
                  margin-top: 5px;
                }
              }
              ul {
                width: 725px;
                margin: 0 auto;
                margin-bottom: 25px;
                @media (max-width: ${MEDIAQUERY}px) {
                  width: calc(100% - 40px);
                }
              }
              li {
                font-family: "Lora";
                font-size: 16px;
                margin-bottom: 25px;
              }
              h2,
              h3 {
                font-family: "Lora";
                font-size: 24px;
                width: 725px;
                margin: 0 auto;
                margin-bottom: 25px;
                @media (max-width: ${MEDIAQUERY}px) {
                  width: 100%;
                }
              }
              pre {
                width: 725px;
                margin: 0 auto;
                margin-bottom: 25px;
                background: rgba(0,0,0,0.7);
                color: white;
                padding: 25px;
                box-sizing: border-box;
                border-radius: 5px;
                @media (max-width: ${MEDIAQUERY}px) {
                  width: 100%;
                  overflow-x: scroll;
                  background: rgba(0,0,0,0.7);
                  color: white;
                  padding: 15px;
                  box-sizing: border-box;
                }
              }
            `}
          >
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
        </div>

        <ul
          css={css`
            float: left;
            width: 100%;
            color: white;
            height: 75px;
            box-sizing: border-box;
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: center;
            li {
              display: inline-block;
              margin-left: 50px;
              margin-right: 50px;
            }
            @media (max-width: ${MEDIAQUERY}px) {
              width: 100%;
              li {
                display: block;
                margin: 0;
              }
            }
          `}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </div>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        type
      }
      body
    }
  }
`
